<template>
  <div>
    <OTitle>Spoof Call</OTitle>
    <CShareThisInline/>
    <CFormDirectCall/>
    <CAdSecondNumber/>
  </div>
</template>

<script>
import OTitle from '../objects/OTitle'
import CFormDirectCall from '../forms/CFormDirectCall'
import { mapActions } from 'vuex'
import CShareThisInline from '@/components/CShareThisInline.vue'
import CAdSecondNumber from '@/components/CAdSecondNumber.vue'

export default {
  name: 'PageDirectCall',
  components: { CAdSecondNumber, CShareThisInline, OTitle, CFormDirectCall },
  methods: {
    ...mapActions(['fetchInfo'])
  },
  mounted () {
    this.fetchInfo()
  }
}
</script>
