<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OForm ref="form" :class="{submitting}">
    <div style="margin-bottom: 1em;" class="info">
      Enter numbers, then click 'Spoof Call' to receive an access number. Call from your phone number
      exactly as listed here. After any form changes, use 'Spoof Call' again!
    </div>
    <PhoneNumberInput
      id="to-number"
      v-model="form.to"
      :disabled="submitting"
      name="target-search"
      placeholder="10 digit number to call"
      required
      autocomplete="section-target tel"
      @invalid="toValid=false"
      @valid="toValid=true"
    >
      Number to call
      <template v-slot:tooltip>
        Target phone number to dial.
      </template>
    </PhoneNumberInput>
    <PhoneNumberInput
      id="from-number"
      v-model="form.from"
      :disabled="submitting"
      name="spoof-search"
      placeholder="10 digit display number"
      required
      autocomplete="section-spoof tel"
      @invalid="fromValid=false"
      @valid="fromValid=true"
      @blur="checkChargeSpoof()"
    >
      Spoof number
      <template v-slot:tooltip>
        Number to display on the target screen.<br/>This can be any valid phone number.
      </template>
    </PhoneNumberInput>
    <div v-if="chargeSpoof" style="margin-top: -1.5rem; margin-bottom: 1.5rem">
      <em>{{ chargeSpoofMessage }}</em>
    </div>

    <PhoneNumberInput
      id="real-number"
      v-model="form.real"
      :disabled="submitting"
      name="own-search"
      placeholder="10 digit number of yours"
      required
      autocomplete="section-own tel"
      @invalid="realValid=false"
      @valid="realValid=true"
    >
      Your phone number
      <template v-slot:tooltip>
        Your real phone number.<br/>We will call you first, you pick up, we bridge the call.
      </template>
    </PhoneNumberInput>
    <OFormSingleChoice
      style="margin-bottom: 2rem"
      v-model="form.voice"
      type="buttons"
      :options="['off', 'man', 'woman']"
    >
      Voice changer
    </OFormSingleChoice>
    <OFormSingleChoice
      style="margin-bottom: 2rem"
      v-model="form.recording"
      type="buttons"
      :options="['off', 'on']"
    >
      Call recording
    </OFormSingleChoice>
    <FormGroup>
      <CCreditsInfoLine></CCreditsInfoLine>
    </FormGroup>

    <div style="margin-bottom: 10px;">
      If you prefer not to use an access number, switch to our original
      <o-link v-if="!$isMobile()" to="home">BRIDGE CALL</o-link>
      <o-link v-if="$isMobile()" to="bridge-call">BRIDGE CALL</o-link>
      dial pad.
    </div>

    <OFormMessage
      :type="error ? 'error' : message ? 'success' : 'info'"
      :message="error || message"></OFormMessage>

    <FormButtons>
      <CCreditButton
        type="button"
        :disabled="submitting"
        style="width: 100%;"
        :value="price"
        division="min"
        @click="directCall"
      >
        Spoof Call
      </CCreditButton>
      <OButton
        theme="outline-primary"
        style="padding: 8px;"
        :class="showTooltip ? 'o-tooltip-container o-tooltip-trigger o-tooltip-show' : ''"
        @click="showTooltip=!showTooltip"
        @blur="showTooltip=false"
      >
        <img class="info-icon" src="../assets/ic_info.svg" style="height: 2em; float: right; margin-top: -5px">
        <o-tooltip anchor="right">
          <div style="text-align: left;">
            Enter numbers, then click <br/>
            'Spoof Call' to receive <br/>
            an access number. Call <br/>
            from your phone number <br/>
            exactly as listed here. <br/>
            After any form changes, <br/>
            use 'Spoof Call' again!
          </div>
        </o-tooltip>
      </OButton>
    </FormButtons>

    <div v-if="chargeSpoof" style="margin-top: -1.5rem; margin-bottom: 1.5rem">
      <em>{{ chargeSpoofMessage }}</em>
    </div>

    <div style="margin-bottom: 20px">
      <o-link :to="{name: 'feedback'}">
        <span :class="$style['a-text']">For support and feedback</span> CLICK HERE</o-link>
    </div>
    <CShareThisInline/>
    <OFormHelp>
      The cost of this service is <em>10 credits</em> per minute.<br/>
      If you choose to use voice changer, additional <em>10 credits</em> per minute will be charged.<br/>
      If you choose to use call recording, additional <em>10 credits</em> per minute will be charged.<br/>
      We charge <em>15 credits</em> for each new display number you try with our system.<br/>
      <em>Please, switch off any call blocker or spam filter so you can receive calls from unknown numbers.</em>
    </OFormHelp>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import PhoneNumberInput from '../objects/forms/OFormPhoneNumberInput'
import OForm from '../objects/forms/OForm'
import OFormSingleChoice from '../objects/forms/OFormSingleChoice'
import FormGroup from '../objects/forms/FormGroup'
import CCreditsInfoLine from '../components/CCreditsInfoLine'
import OFormHelp from '../objects/forms/OFormHelp'
import { mapMutations } from 'vuex'
import CCreditButton from '../components/CCreditButton'
import CShareThisInline from '../components/CShareThisInline'
import OLink from '@/objects/OLink'
import OTooltip from '@/objects/OTooltip.vue'
import OButton from '@/objects/OButton.vue'
import FormButtons from '@/objects/forms/FormButtons.vue'

const getApi = () => import('@/services/api')

const OFormMessage = () => import('@/objects/forms/OFormMessage')

export default {
  name: 'CFormDirectCall',
  components: {
    FormButtons,
    OButton,
    OTooltip,
    CCreditButton,
    OFormHelp,
    CCreditsInfoLine,
    FormGroup,
    OForm,
    PhoneNumberInput,
    OFormSingleChoice,
    OFormMessage,
    CShareThisInline,
    OLink
  },
  mixins: [apiForm],
  data: function () {
    return {
      showTooltip: false,
      form: {
        email: this.$store.getters.getEmail,
        password: this.$store.getters.getPassword,
        from: this.$store.getters.getFrom,
        to: this.$store.getters.getTo,
        real: this.$store.getters.getReal,
        voice: this.$store.getters.getVoice,
        recording: this.$store.getters.getRecording
      },
      message: null,
      chargeSpoof: false,
      chargeSpoofMessage: ''
    }
  },
  computed: {
    price () {
      return 10 + (this.form.voice !== 'off' ? 10 : 0) + (this.form.recording !== 'off' ? 10 : 0)
    }
  },
  mounted () {
    this.setLogin({ page: 'spoof-call' })
  },
  methods: {
    ...mapMutations(['setPhones', 'setLogin']),
    async directCall () {
      this.error = null
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity()
        return
      }
      this.initCall()
      const api = await getApi()
      const result = await api.callRequest(this.form)
      if (result.error) {
        this.error = result.message
        return false
      }
      window.location.href = 'tel:' + result.service_phone
    },
    validatePhoneFields () {
      if (!this.form.to) {
        let valueNumber = document.getElementById('to-number').value
        this.form.to = '+1' + valueNumber.replace(/\D/g, '')
      }
      if (!this.form.from) {
        let valueNumber = document.getElementById('from-number').value
        this.form.from = '+1' + valueNumber.replace(/\D/g, '')
      }
      if (!this.form.real) {
        let valueNumber = document.getElementById('real-number').value
        this.form.real = '+1' + valueNumber.replace(/\D/g, '')
      }
    },
    initCall () {
      this.validatePhoneFields()
      this.setPhones({
        from: this.form.from,
        to: this.form.to,
        real: this.form.real,
        voice: this.form.voice,
        recording: this.form.recording
      })
    },
    async checkChargeSpoof () {
      this.chargeSpoof = false
      const api = await getApi()
      const result = await api.chargeSpoof({
        user: this.form.email,
        pass: this.form.password,
        number: this.form.from
      })
      if (result.success) {
        this.chargeSpoof = result.charge
        this.chargeSpoofMessage = result.message
      }
    }
  },
  formSubmitted ({ message }) {
    this.$router.push({ name: 'call-success' })
  }
}
</script>
<style lang="scss" module>
.a-text {
  color: #000000 !important;
  text-transform: none !important;
}
</style>
