<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OForm ref="form" @submit="directCall" :class="{submitting}">
    <div style="margin-bottom: 1em;" class="info">
      Enter numbers, then click 'Spoof Call' to receive an access number. Call from your phone number
      exactly as listed here. After any form changes, use 'Spoof Call' again!
    </div>
    <PhoneNumberInput
      id="to-number"
      v-model="form.to"
      :disabled="submitting"
      name="target-search"
      placeholder="10 digit number to call"
      required
      autocomplete="section-target tel"
      @invalid="toValid=false"
      @valid="toValid=true"
    >
      Number to call
      <template v-slot:tooltip>
        Target phone number to dial.
      </template>
    </PhoneNumberInput>
    <PhoneNumberInput
      id="from-number"
      v-model="form.from"
      :disabled="submitting"
      name="spoof-search"
      placeholder="10 digit display number"
      required
      autocomplete="section-spoof tel"
      @invalid="fromValid=false"
      @valid="fromValid=true"
    >
      Spoof number
      <template v-slot:tooltip>
        Number to display on the target screen.<br/>This can be any valid phone number.
      </template>
    </PhoneNumberInput>
    <PhoneNumberInput
      id="real-number"
      v-model="form.real"
      :disabled="submitting"
      name="own-search"
      placeholder="10 digit number of yours"
      required
      autocomplete="section-own tel"
      @invalid="realValid=false"
      @valid="realValid=true"
    >
      Your phone number
      <template v-slot:tooltip>
        Your real phone number.<br/>We will call you first, you pick up, we bridge the call.
      </template>
    </PhoneNumberInput>
    <div @click="showErrorVoice()">
      <OFormSingleChoice
        style="margin-bottom: 2rem"
        :value="'off'"
        type="buttons"
        :options="['off', 'man', 'woman']"
      >
        Voice changer
      </OFormSingleChoice>
    </div>
    <OFormError :message="errorVoice"></OFormError>
    <div @click="showErrorRecording()">
      <OFormSingleChoice
        style="margin-bottom: 2rem"
        :value="'off'"
        type="buttons"
        :options="['off', 'on']"
      >
        Call recording
      </OFormSingleChoice>
    </div>
    <OFormError :message="errorRecording"></OFormError>

    <OFormTOS></OFormTOS>
    <OFormMessage
      :type="error ? 'error' : message ? 'success' : 'info'"
      :message="error || message"></OFormMessage>

    <div style="margin-bottom: 10px;">
      If you prefer not to use an access number, switch to our original
      <o-link v-if="!$isMobile()" to="home">BRIDGE CALL</o-link>
      <o-link v-if="$isMobile()" to="bridge-call">BRIDGE CALL</o-link>
      dial pad.
    </div>

    <FormButtons>
      <OFormSubmitButton :disabled="submitting" style="width: 66%">
        Spoof Call
      </OFormSubmitButton>
    </FormButtons>

    <div style="margin-bottom: 20px">
      <o-link :to="{name: 'feedback'}">
        <span :class="$style['a-text']">For support and feedback</span> CLICK HERE</o-link>
    </div>
    <CShareThisInline/>
    <OFormHelp>
      <em>Please, switch off any call blocker or spam filter so you can receive calls from unknown numbers.</em>
      Our free caller id faker is limited to 40 seconds. You can remove all limits and add <em>voice changer</em>
      and <em>call recording</em> if you Join.
    </OFormHelp>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import PhoneNumberInput from '../objects/forms/OFormPhoneNumberInput'
import OForm from '../objects/forms/OForm'
import OFormSingleChoice from '../objects/forms/OFormSingleChoice'
import OFormHelp from '../objects/forms/OFormHelp'
import { mapMutations } from 'vuex'
import CShareThisInline from '../components/CShareThisInline'
import OLink from '@/objects/OLink'
import FormButtons from '@/objects/forms/FormButtons.vue'
import OFormError from '@/objects/forms/OFormError.vue'
import OFormTOS from '@/objects/forms/OFormTOS.vue'
import OFormSubmitButton from '@/objects/forms/OFormSubmitButton.vue'

const getApi = () => import('@/services/api')

const OFormMessage = () => import('@/objects/forms/OFormMessage')

export default {
  name: 'CFormDirectCall',
  components: {
    OFormSubmitButton,
    OFormTOS,
    OFormError,
    FormButtons,
    OFormHelp,
    OForm,
    PhoneNumberInput,
    OFormSingleChoice,
    OFormMessage,
    CShareThisInline,
    OLink
  },
  mixins: [apiForm],
  data: function () {
    return {
      showTooltip: false,
      form: {
        email: this.$store.getters.getEmail,
        password: this.$store.getters.getPassword,
        from: this.$store.getters.getFrom,
        to: this.$store.getters.getTo,
        real: this.$store.getters.getReal,
        voice: this.$store.getters.getVoice,
        recording: this.$store.getters.getRecording,
        token: null
      },
      message: null,
      errorVoice: null,
      errorRecording: null
    }
  },
  computed: {
    price () {
      return 10 + (this.form.voice !== 'off' ? 10 : 0) + (this.form.recording !== 'off' ? 10 : 0)
    }
  },
  mounted () {
    this.setLogin({ page: 'spoof-call' })
  },
  methods: {
    ...mapMutations(['setPhones', 'setLogin']),
    async directCall () {
      this.error = null
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity()
        return
      }
      this.initCall()

      await this.$recaptchaLoaded()
      this.form.token = await this.$recaptcha('freespoofcall')

      const api = await getApi()
      const result = await api.callFreeRequest(this.form)
      if (result.error) {
        this.error = result.message
        return false
      }
      window.location.href = 'tel:' + result.service_phone
    },
    validatePhoneFields () {
      if (!this.form.to) {
        let valueNumber = document.getElementById('to-number').value
        this.form.to = '+1' + valueNumber.replace(/\D/g, '')
      }
      if (!this.form.from) {
        let valueNumber = document.getElementById('from-number').value
        this.form.from = '+1' + valueNumber.replace(/\D/g, '')
      }
      if (!this.form.real) {
        let valueNumber = document.getElementById('real-number').value
        this.form.real = '+1' + valueNumber.replace(/\D/g, '')
      }
    },
    initCall () {
      this.validatePhoneFields()
      this.setPhones({
        from: this.form.from,
        to: this.form.to,
        real: this.form.real,
        voice: this.form.voice,
        recording: this.form.recording
      })
    },
    showErrorVoice () {
      this.errorVoice = 'You need to login in order to use VOICE CHANGER.'
    },
    showErrorRecording () {
      this.errorRecording = 'You need to login in order to use CALL RECORDING.'
    }
  },
  formSubmitted ({ message }) {
    this.$router.push({ name: 'call-success' })
  }
}
</script>
<style lang="scss" module>
.a-text {
  color: #000000 !important;
 text-transform: none !important;
}
</style>
